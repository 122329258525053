<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat color="bg_section">
        <v-toolbar-title>Crea Nuovo Prodotto</v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-card-text>
        <ProductsForm :title="null" @submitted="onSubmitted" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import ProductsForm from '@components/products/ProductsForm.vue'
export default {
  page: {
    title: 'Nuovo Prodotto',
  },
  name: 'ProductCreate',
  components: { ProductsForm },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapMutations('products', {
      reset: 'RESET_CURRENT',
    }),
    onSubmitted(id) {
      this.$router.push({ name: 'products_detail', params: { id } })
    },
  },
}
</script>
