<template>
  <Form
    :hide-reset="edit"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <v-row>
      <FormItem v-model="brand" name="Marca" rules="required" />
      <FormItem v-model="model" name="Modello" rules="required" />
      <FormItem v-model="type" name="Tipo Prodotto" rules="required" />
      <FormItem v-model="part_number" name="Codice componente" />
      <FormItem v-model="variant" name="Variante Prodotto" />
      <FormItem v-model="storage" name="Storage Prodotto" />
      <FormItem v-model="color" name="Colore" />
      <FormItem v-model="active" name="Attivo" type="switch" dense />

      <FormItem
        v-model="description"
        type="textarea"
        name="Descrizione"
        :cols="12"
        :sm="12"
        :md="12"
        :lg="8"
        :xl="8"
      />
    </v-row>

    <v-divider class="my-6" />

    <v-row>
      <FormItem
        v-model="cost_hardware"
        type="number"
        step="0.01"
        min="0"
        name="Costo Hardware"
        :cols="4"
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
        rules="min_value:0"
      />
      <FormItem
        v-model="cost_software"
        type="number"
        step="0.01"
        min="0"
        name="Costo Software"
        :cols="4"
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
        rules="min_value:0"
      />
      <FormItem
        v-model="cost_setup_base"
        type="number"
        step="0.01"
        min="0"
        name="Costo installazione Tecnico"
        :cols="4"
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
        rules="min_value:0"
      />
      <FormItem
        v-model="cost_setup_additional"
        type="number"
        step="0.01"
        min="0"
        name="Altri costi installazione"
        :cols="4"
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
        rules="min_value:0"
      />
      <FormItem
        v-model="cost_maintenance"
        type="number"
        step="0.01"
        min="0"
        name="Costo Manutenzione"
        :cols="4"
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
        rules="min_value:0"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="cost_management"
        type="number"
        step="0.01"
        min="0"
        name="Costo Gestione"
        :cols="4"
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
        rules="min_value:0"
      />
      <FormItem
        v-model="cost_shipping"
        type="number"
        step="0.01"
        min="0"
        name="Costo Trasporto"
        :cols="4"
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
        rules="min_value:0"
      />
      <FormItem
        v-model="cost_insurance"
        type="number"
        step="0.01"
        min="0"
        name="Costo Assicurazione"
        :cols="4"
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
        rules="min_value:0"
      />
      <FormItem
        v-model="cost_additional"
        type="number"
        step="0.01"
        min="0"
        name="Costo Accessori"
        :cols="4"
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
        rules="min_value:0"
      />
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'

const { mapFields } = createHelpers({
  getterType: 'products/getCurrent',
  mutationType: 'products/SET_CURRENT_FIELDS',
})

export default {
  name: 'ProductsForm',
  components: {
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  computed: {
    ...mapFields([
      'brand',
      'model',
      'type',
      'part_number',
      'external_id',
      'description',
      'active',
      'variant',
      'storage',
      'color',
      'cost_hardware',
      'cost_software',
      'cost_setup_base',
      'cost_setup_additional',
      'cost_maintenance',
      'cost_management',
      'cost_shipping',
      'cost_insurance',
      'cost_additional',
    ]),
    ...mapState('products', {
      id: (state) => state.current.id,
      loading: (state) => state.loading,
      statuses: (state) => state.statuses,
    }),
  },
  methods: {
    ...mapMutations('products', {
      reset: 'RESET_CURRENT',
    }),
    ...mapActions('products', ['create', 'update', 'remove']),
    async onSubmit() {
      let data
      this.$emit('submit')

      if (!this.edit) {
        data = await this.create()
      } else {
        data = await this.update()
      }

      this.$emit('submitted', data.id)
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
